import React, { useState, useEffect, useCallback } from 'react';
import bridge from '@vkontakte/vk-bridge';
import { Panel, FixedLayout, Button } from '@vkontakte/vkui';
import VKStories from 'vk-stories';
import './index.css';

const hash = window.location.hash.slice(1, window.location.hash.length);
const is_desktop = window.location.search.indexOf("desktop") !== -1;
const params = hash.split('&').reduce((a, x) => {
    const data = x.split('=');
    return {
        ...a,
        [data[0]]: data[1],
    };
}, {});

const generateDesktop = (templateUrl, fields = []) => new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    canvas.width = 1260;
    canvas.height = 1200;

    const ctx = canvas.getContext("2d");

    const background = new Image();
    background.onload = () => {
        ctx.drawImage(background, 0, 0);

        fields.forEach((field) => {
            ctx.font = field.font;
            ctx.textAlign = field.align;
            ctx.fillStyle = field.color;
            ctx.fillText(field.value, field.x, field.y);
        });

        resolve(canvas.toDataURL());
    };
    background.src = templateUrl;
});

const wordPad = (num, t, ta, tov) => {
    num = num % 100;
    if (num % 10 === 1 && (num < 10 || num > 20)) return t;
    else if (num % 10 >= 2 && num % 10 <= 4 && (num < 10 || num > 20)) return ta;
    else return tov;
};

const fields = {
    tasks: [
        {
            x: 540,
            y: 800,
            value: "В карантин-боте я выполнил",
            font: "57px sftm",
            align: "center",
            color: "rgba(255, 255, 255, .8)"
        },
        {
            x: 540,
            y: 1000,
            value: params.counter || 0,
            font: "207px sfdb",
            align: "center",
            color: "#FFFFFF"
        },
        {
            x: 540,
            y: 1100,
            value: `${wordPad(params.counter || 0, "задание", "задания", "заданий")} и получил стикеры`,
            font: "57px sftm",
            align: "center",
            color: "rgba(255, 255, 255, .8)"
        },
        {
            x: 540,
            y: 1175,
            value: "за каждое!",
            font: "57px sftm",
            align: "center",
            color: "rgba(255, 255, 255, .8)"
        },
    ],
    dating: [
        {
            x: 540,
            y: 800,
            value: "В карантин-боте я приобрёл",
            font: "57px sftm",
            align: "center",
            color: "rgba(255, 255, 255, .8)"
        },
        {
            x: 540,
            y: 1000,
            value: params.counter || 0,
            font: "207px sfdb",
            align: "center",
            color: "#FFFFFF"
        },
        {
            x: 540,
            y: 1100,
            value: `${wordPad(params.counter || 0, "нового знакомого", "новых знакомых", "новых знакомых")} среди`,
            font: "57px sftm",
            align: "center",
            color: "rgba(255, 255, 255, .8)"
        },
        {
            x: 540,
            y: 1175,
            value: "узников самоизоляции!",
            font: "57px sftm",
            align: "center",
            color: "rgba(255, 255, 255, .8)"
        },
    ],
    desktop_tasks: [
        {
            x: 315 * 2,
            y: 270 * 2,
            value: "В карантин-боте я выполнил",
            font: "40px sftm",
            align: "center",
            color: "rgba(255, 255, 255, .8)"
        },
        {
            x: 315 * 2,
            y: 348 * 2,
            value: params.counter || 0,
            font: "144px sfdb",
            align: "center",
            color: "#FFFFFF"
        },
        {
            x: 315 * 2,
            y: 388 * 2,
            value: `${wordPad(params.counter || 0, "задание", "задания", "заданий")} и получил стикеры`,
            font: "40px sftm",
            align: "center",
            color: "rgba(255, 255, 255, .8)"
        },
        {
            x: 315 * 2,
            y: 412 * 2,
            value: "за каждое!",
            font: "40px sftm",
            align: "center",
            color: "rgba(255, 255, 255, .8)"
        },
    ],
    desktop_dating: [
        {
            x: 315 * 2,
            y: 270 * 2,
            value: "В карантин-боте я приобрёл",
            font: "40px sftm",
            align: "center",
            color: "rgba(255, 255, 255, .8)"
        },
        {
            x: 315 * 2,
            y: 348 * 2,
            value: params.counter || 0,
            font: "144px sfdb",
            align: "center",
            color: "#FFFFFF"
        },
        {
            x: 315 * 2,
            y: 388 * 2,
            value: `${wordPad(params.counter || 0, "нового знакомого", "новых знакомых", "новых знакомых")} среди`,
            font: "40px sftm",
            align: "center",
            color: "rgba(255, 255, 255, .8)"
        },
        {
            x: 315 * 2,
            y: 412 * 2,
            value: "узников самоизоляции!",
            font: "40px sftm",
            align: "center",
            color: "rgba(255, 255, 255, .8)"
        },
    ]
};

const Share = ({ id }) => {
    const type = params.type || "tasks";
    const [ finalImage, setFinalImage ] = useState(null);
    const [ desktopImage, setDesktopImage ] = useState(null);

    const share = useCallback(() => {
        bridge.send('VKWebAppShowStoryBox', {
            background_type : 'image',
            blob : finalImage,
            locked: true,
            attachment: {
                text: "open",
                type: "url",
                url: "https://vk.com"
            }
        });
    }, [ finalImage ]);

    useEffect(() => {
        setTimeout(() => {
            VKStories.generateStoryFromTemplate(`/stories/${type}.png`, fields[type])
                .then(setFinalImage);

            if (is_desktop) {
                generateDesktop(`/stories/desktop_${type}.png`, fields['desktop_' + type])
                    .then(setDesktopImage);
            }
        }, 500);
    }, []);

    return (
        <Panel id={id} separator={false} style={{ position: 'fixed' }}>
            <h1 style={{ fontFamily: 'sftm', position: 'absolute', top: -100 }}>font</h1>
            <h1 style={{ fontFamily: 'sfdb', position: 'absolute', top: -100 }}>font</h1>
            {finalImage && <div className={`bg bg-${type}-top`} />}
            {finalImage && <div className={`bg bg-${type}-bottom`} />}
            <div
                style={{
                    position: 'absolute',
                    height: '100vh',
                    width: '100vw',
                    backgroundImage: `url(${is_desktop ? desktopImage : finalImage})`,
                    backgroundPosition: 'center center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            />
            <FixedLayout vertical="bottom">
                <div
                    style={{
                        marginBottom: is_desktop ? 20 : 25,
                        marginLeft: is_desktop ? 20 : 24,
                    }}>
                    <Button
                        style={{ height: 36, fontWeight: '600' }}
                        mode="overlay_primary"
                        onClick={share}
                        size="m"
                    >
                        Поделиться в истории
                    </Button>
                </div>
            </FixedLayout>
        </Panel>
    );
};

export default Share;
