import React from 'react';
import { View } from '@vkontakte/vkui';

import Share from './Share';

const App = () => {
    return (
        <View activePanel="share" header={false}>
            <Share id="share" />
        </View>
    );
};

export default App;
